<!--
 * @Author: 黄孝娟
 * @Date: 2019-12-24 09:15:34
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-03-18 10:18:57
 -->
<template>
  <div class="container">
    <div class="title">
      <div class="line">
        <div class="item">
          <div class="img">
            <img src="@/assets/agent/img/t_1.png" /><br />
          </div>
          <span class="number">{{numForm.kuNUM}}</span><br />
          <span class="grey">客户总数</span>
        </div>
        <div class="item">
          <div class="img">
            <img src="@/assets/agent/img/t_2.png" /><br />
          </div>
          <span class="number">{{numForm.wzRD}}</span><br />
          <span class="grey">文章浏览总数</span>
        </div>
        <div class="item">
          <div class="img">
            <img src="@/assets/agent/img/t_3.png" /><br />
          </div>
          <span class="number">{{numForm.wzZF}}</span><br />
          <span class="grey">文章转发总数</span>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <div class="img">
            <img src="@/assets/agent/img/t_6.png" /><br />
          </div>
          <span class="number">{{numForm.zxnum}}</span><br />
          <span class="grey">被咨询数</span>
        </div>
        <div class="item">
          <div class="img">
            <img src="@/assets/agent/img/t_5.png" /><br />
          </div>
          <span class="number">{{numForm.mpDZ}}</span><br />
          <span class="grey">被点赞数</span>
        </div>
        <div class="item">
          <div class="img">
            <img src="@/assets/agent/img/t_4.png" /><br />
          </div>
          <span class="number">{{numForm.mpZF}}</span><br />
          <span class="grey">名片被转总数</span>
        </div>
      </div>
    </div>
    <div class="chartsPic">
      <div class="ch_title">
        <div class="t_span">
          <div class="addnum">
            <div class="a_left">
              <span>新增客户数</span>
            </div>
            <div class="a_right">
              <div @click="isAddNum('7')"
                   :style="isNum == '7'? `backgroundColor:${colorprimary}` :''"
                   :class="isNum == '7'?'select':'notselect'">近7日</div>
              <div @click="isAddNum('15')"
                   :style="isNum == '15'? `backgroundColor:${colorprimary}` :''"
                   :class="isNum == '15'?'select':'notselect'">近15日</div>
              <div @click="isAddNum('30')"
                   :style="isNum == '30'? `backgroundColor:${colorprimary}` :''"
                   :class="isNum == '30'?'select':'notselect'">近30日</div>
            </div>
          </div>
        </div>
        <div id="addChart"
             :style="{width: '100%', height: '250px'}"></div>
      </div>
    </div>
    <div class="chartsPic">
      <div class="ch_title">
        <div class="t_span">
          <div class="addnum">
            <div class="a_left">
              <span>阅读数</span>
            </div>
            <div class="a_right">
              <div @click="isReadNum('7')"
                   :style="isRead == '7'? `backgroundColor:${colorprimary}` :''"
                   :class="isRead == '7'?'select':'notselect'">近7日</div>
              <div @click="isReadNum('15')"
                   :style="isRead == '15'? `backgroundColor:${colorprimary}` :''"
                   :class="isRead == '15'?'select':'notselect'">近15日</div>
              <div @click="isReadNum('30')"
                   :style="isRead == '30'? `backgroundColor:${colorprimary}` :''"
                   :class="isRead == '30'?'select':'notselect'">近30日</div>
            </div>
          </div>
        </div>
        <div id="readNumChart"
             :style="{width: '100%', height: '250px'}"></div>
      </div>
    </div>
    <div class="chartsPic">
      <div class="ch_title">
        <div class="t_span">
          <span>客户兴趣占比</span>
        </div>
        <div id="intrinsicChart"
             :style="{width: '100%', height: '300px'}"></div>
      </div>
    </div>
    <div class="chartsPic">
      <div class="ch_title">
        <div class="t_span">
          <span>近15日客户活跃度</span>
        </div>
        <div id="myChart"
             :style="{width: '100%', height: '300px'}"></div>
      </div>
    </div>
    <div class="chartsPic bottom">
      <div class="ch_title">
        <div class="t_span">
          <span>客户互动</span>
        </div>
        <div class="progressBar">
          <div class="aline">
            <div class="pic color1">
              <img src="@/assets/agent/img/res_file.png" />
            </div>
            <div class="name"><span>点赞</span></div>
            <div class="number"><span>{{Form.mpDZ}}</span></div>
            <div class="line">
              <div class="bgcolor">
                <div class="violet lightred mpDZ"></div>
              </div>
            </div>
          </div>
          <div class="aline">
            <div class="pic color2">
              <img src="@/assets/agent/img/yew_file.png" />
            </div>
            <div class="name"><span>在线咨询</span></div>
            <div class="number"><span>{{Form.zxnum}}</span></div>
            <div class="line">
              <div class="bgcolor">
                <div class="violet orange zxnum"></div>
              </div>
            </div>
          </div>
          <!-- <div class="aline">
            <div class="pic"></div>
            <div class="name"><span>保存名片</span></div>
            <div class="number"><span>12345</span></div>
            <div class="line">
              <div class="bgcolor">
                <div class="violet"></div>
              </div>
            </div>
          </div> -->
          <div class="aline">
            <div class="pic"></div>
            <div class="name"><span>浏览文章</span></div>
            <div class="number"><span>{{Form.wzRD}}</span></div>
            <div class="line">
              <div class="bgcolor">
                <div class="violet wzRD"></div>
              </div>
            </div>
          </div>
          <div class="aline">
            <div class="pic"></div>
            <div class="name"><span>浏览产品</span></div>
            <div class="number"><span>{{Form.cpRD}}</span></div>
            <div class="line">
              <div class="bgcolor">
                <div class="violet cpRD"></div>
              </div>
            </div>
          </div>
          <div class="aline">
            <div class="pic"></div>
            <div class="name"><span>浏览名片</span></div>
            <div class="number"><span>{{Form.mpRD}}</span></div>
            <div class="line">
              <div class="bgcolor">
                <div class="violet mpRD"></div>
              </div>
            </div>
          </div>
          <div class="aline">
            <div class="pic"></div>
            <div class="name"><span>浏览官网</span></div>
            <div class="number"><span>{{Form.gwRD}}</span></div>
            <div class="line">
              <div class="bgcolor">
                <div class="violet gwRD"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTDRadar, getTDRecordKH, getTDRecordRD } from '@/api/agent/agent'
import { getStorage } from '@/lib/util'
import { wechatshare } from '@/lib/wechat_share'
export default {
  data () {
    return {
      user: '',
      isNum: '7',
      isRead: '7',
      numForm: {
        kuNUM: '',
        mpDZ: '',
        mpRD: '',
        mpZF: '',
        wzRD: '',
        wzZF: '',
        zxnum: ''
      },
      x_Day: [],
      y_Day: [],
      x_Read: [],
      y_Read: [],
      x_Active: [],
      y_Active: [],
      List: [],
      Form: {
        cpRD: '',
        gwRD: '',
        mpDZ: '',
        mpRD: '',
        wzRD: '',
        zxnum: ''
      },
      colorprimary: ''
    }
  },
  created () {
    this.user = getStorage('u_s', {})
    this.colorprimary = getStorage('theme', '')
    this.getData()
  },
  mounted () {
    wechatshare('', '', '', '', '', '', true)
  },
  methods: {
    getData () {
      getTDRadar({ empno: this.user.empno }).then(res => {
        this.numForm = res.data.data.numList
        res.data.data.activeList.forEach((item, index) => {
          this.x_Active.push(item.time)
          this.y_Active.push(item.num)
        })
        this.List = res.data.data.interest
        this.Form = res.data.data.khInteractive
        let values = Object.values(this.Form)
        let max = Math.max(...values)
        if (max != 0) {
          document.querySelector('.mpDZ').style.width = `${this.Form.mpDZ / max * 100}%`
          document.querySelector('.zxnum').style.width = `${this.Form.zxnum / max * 100}%`
          document.querySelector('.wzRD').style.width = `${this.Form.wzRD / max * 100}%`
          document.querySelector('.cpRD').style.width = `${this.Form.cpRD / max * 100}%`
          document.querySelector('.mpRD').style.width = `${this.Form.mpRD / max * 100}%`
          document.querySelector('.gwRD').style.width = `${this.Form.gwRD / max * 100}%`
        }
        this.getDay()
        this.getReadNum()
        this.drawLine()
      })
    },
    // 主管雷达-统计day新增获客数列表
    getDay () {
      getTDRecordKH({ empno: this.user.empno, KHday: this.isNum }).then(res => {
        res.data.data.hkDaynum.forEach((item, index) => {
          this.x_Day.push(item.time)
          this.y_Day.push(item.num)
          this.drawAddChart()
        })
      })
    },
    // 主管雷达-统计day阅读数列表
    getReadNum () {
      getTDRecordRD({ empno: this.user.empno, Readday: this.isRead }).then(res => {
        res.data.data.rdDaynum.forEach((item, index) => {
          this.x_Read.push(item.time)
          this.y_Read.push(item.num)
          this.drawReadNumChart()
        })
      })
    },
    isAddNum (val) {
      this.x_Day = []
      this.y_Day = []
      this.isNum = val
      this.getDay()
    },
    isReadNum (val) {
      this.x_Read = []
      this.y_Read = []
      this.isRead = val
      this.getReadNum()
    },
    drawAddChart () {
      let addChart = this.$echarts.init(document.getElementById('addChart'))
      // 绘制新增客户数图表
      addChart.setOption({
        color: ['#1A9FFA'],
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '6%',
          right: '15%',
          top: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.x_Day
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        series: [
          {
            name: '新增客户数',
            type: 'line',
            stack: '总量',
            data: this.y_Day
          }
        ]
      })
    },
    drawReadNumChart () {
      let readNumChart = this.$echarts.init(document.getElementById('readNumChart'))
      // 绘制阅读数图表
      readNumChart.setOption({
        color: ['#1A9FFA'],
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '6%',
          right: '15%',
          top: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.x_Read
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        series: [
          {
            name: '阅读数',
            type: 'line',
            stack: '总量',
            data: this.y_Read
          }
        ]
      })
    },
    drawLine () {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制近15日客户活跃度图表
      myChart.setOption({
        color: ['#1A9FFA'],
        tooltip: {},
        grid: {
          left: '5%',
          right: '15%',
          top: '5%',
          containLabel: true
        },
        xAxis: {
          data: this.x_Active
        },
        yAxis: {
          minInterval: 1
        },
        series: [{
          name: '近15日客户活跃度',
          type: 'bar',
          data: this.y_Active
        }]
      })
      let intrinsicChart = this.$echarts.init(document.getElementById('intrinsicChart'))
      // 绘制客户兴趣占比图表
      intrinsicChart.setOption(
        {
          color: ['#F17182', '#F8AC55', '#647CF8'],
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
            orient: 'vertical',
            x: 'left',
            data: ['对团队感兴趣', '对产品感兴趣', '对公司感兴趣']
          },
          series: [
            {
              name: '客户兴趣占比',
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: this.List
            }
          ]
        }
      )
    }
  }
}
</script>
<style lang="stylus" scoped>
@import './index.styl';
</style>
